body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fcfcfc;
}

.App {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@font-face {
  font-family: Roboto-Thin;
  font-style: normal;
  src: url("assets/font/Roboto-Thin.ttf");
}

@font-face {
  font-family: Roboto-Light;
  font-style: normal;
  src: url("assets/font/Roboto-Light.ttf");
}

@font-face {
  font-family: Roboto-Regular;
  font-style: normal;
  src: url("assets/font/Roboto-Regular.ttf");
}

@font-face {
  font-family: Roboto-Medium;
  font-style: normal;
  src: url("assets/font/Roboto-Medium.ttf");
}

@font-face {
  font-family: Roboto-Bold;
  font-style: normal;
  src: url("assets/font/Roboto-Bold.ttf");
}

@font-face {
  font-family: Roboto-Black;
  font-style: normal;
  src: url("assets/font/Roboto-Black.ttf");
}

div {
  box-sizing: border-box;
}
a {
  text-decoration: unset !important;
}
